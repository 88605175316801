.vmsTable {
  overflow-y: auto;
  --nested-row-border: 3px solid #bbbbbb;
  --row-transition-duration: 50ms;
}

.vmsTable table {
  width: 100%;
  border-spacing: 0;
  border: 1px solid #cccccc;
  background-color: #ffffff;
}
.vmsTable table thead {
  border-bottom: 2px solid #d9d9d9;
}
.vmsTable table thead tr {
  background-color: #f5f5f5;
}
.vmsTable table thead tr th {
  text-align: left;
}

.vmsTable tr {
  transition: background-color var(--row-transition-duration);
}
.vmsTable tr:last-child td {
  border-bottom: 0;
}
.vmsTable tr:hover {
  background-color: #f0f0f0;
}
.vmsTable tr:nth-of-type(2n) {
  background-color: #f6f6f6;
}
.vmsTable tr:nth-of-type(2n):hover {
  background-color: #eeeeee;
}

.vmsTable tr .actionIcons {
  opacity: 0;
  transition: opacity var(--row-transition-duration);
  cursor: pointer;
  font-size: 16px;
  color: #555555;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.vmsTable tr:hover .actionIcons {
  opacity: 1;
}

.vmsTable th,
.vmsTable td {
  max-width: 250px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
}

.vmsTable th {
  padding: 10px;
}
.vmsTable td {
  padding: 4px 10px;
}

.vmsTable th:last-child,
.vmsTable td:last-child {
  border-right: 0;
}

.tableRow.nested.first {
  border-top: var(--nested-row-border);
}
.tableRow.nested.last {
  border-bottom: var(--nested-row-border);
}
