.ratingNodeLayout {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  gap: 12px
}
.ratingNode {
  display: flex;
  flex: 1 1 auto;
}
.abstainNode {
  display: flex;
  flex: 0 1 100px;
}
.nodeLabels {
  width: 22px;
  writing-mode: vertical-lr;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 4px 0;
  font-weight: 500;
  transform: rotate(180deg);
}
.nodeLabels > :only-child {
  margin-top: auto;
  margin-bottom: auto;
}
.nodeBorder {
  position: relative;
  border: 5px solid black;
  display: flex;
  flex: 1 1 0;
  width: 100%;
}
