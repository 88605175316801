.tabStyle {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  background-color: var(--background-blue);
}

.linkAndCopy {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.qrCode {
  padding: 12px, 0;
}

.openCloseSession {
  align-self: flex-end;
}

.closedSvg {
  width: 25%;
}
