.container {
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  text-align: center;
}

.registrationForm {
  width: 300px;
}

.formControls {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.formControls > button:only-child {
  margin-left: auto;
}

ul.participantsList {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
ul.participantsList li {
  padding: 12px;
  min-width: 200px;
  background-color: #ff9e00;
  cursor: pointer;
  text-align: center;
  border: 1px solid transparent;
  transition: all 100ms ease-in-out
}
ul.participantsList li:focus,
ul.participantsList li:hover {
  background-color: #febf5b;
  outline: none;
  box-shadow: 0 0 5px #40a9ff;
  border: 1px solid #40a9ff;
}
