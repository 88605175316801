@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-Hair.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-HairItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-UltraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-ExtraBoldItalic.woff') format('woff');
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Fira Sans';
  src: url('assets/fonts/fira/woff/FiraSans-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Pragati Narrow';
  src: url('assets/fonts/pragati/tff/PragatiNarrow-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Pragati Narrow';
  src: url('assets/fonts/pragati/tff/PragatiNarrow-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

body {
  font-family: 'Fira Sans';
  font-size: 14px;
  --antd-black: rgba(0, 0, 0, .85);
}

#root {
  --selected-blue: rgb(186, 231, 255);
  --background-blue: rgba(186, 231, 255, 0.4);
  --color-blue-dark: #22303b;
  --color-blue-link: #1890ff;
  --app-content-background-color: #e6e8e9;
}
