.container {
  --link-icon-color: #1890ff;
  flex: 1 1 0;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
}

.ratingInterfaceContainer {
  flex: 1 1 0;
  display: flex;
  justify-content: center;
  gap: 24px;
}
.ratingInterface {
  flex: 0 1 500px;
  min-width: 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.desktopRatingNotesContainer {
  flex: 0 1 500px;
  display: none;
  flex-direction: column;
}
.desktopRatingNotesContainerSpace {
  flex: 2 1 0;
}
.desktopRatingNotesTextArea {
  flex: 1 1 0;
}

.decisionLabel {
  display: flex;
  align-items: center;
  justify-content: center;
}
.decisionNameOverflowEllipsis {
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}
.contextLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
}
.contextLabel h2 {
  margin-bottom: 0;
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contextLabel .infoIcon {
  color: var(--link-icon-color)
}

.controlsContainer {
  display: flex;
  justify-content: center;
}
.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex: 0 1 500px;
}
.controlIcon {
  color: var(--link-icon-color);
  font-size: 36px;
}

@media screen and (min-width: 900px) {
  .decisionLabel, .contextLabel {
    justify-content: flex-start;
  }
  .notesDrawerTrigger {
    display: none;
  }
  .desktopRatingNotesContainer {
    display: flex;
  }
}
